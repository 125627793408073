.App {
  text-align: center;
  user-select: none;
}

.App-logo {
  height: 10rem;
  width: 10rem;
  border-radius: 4rem;
  padding: 3rem;
  pointer-events: none;
  background-image: linear-gradient(to bottom, #fff, #cbcbcb);
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(128, 128, 128);
  font-size: 1.2rem;
}

.subheading {
  padding: 0 1rem;
}

.copyright {
  font-size: 0.8rem;
  padding: 2rem 1rem;
}

.App-panel {
  padding: 0 1.5rem;
  margin: 2rem;
  font-weight: bold;
  font-size: 2rem;
  color: #000;
}

.App-circle-container {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.App-circle-container > .App-circle {
  margin-left: -0.25rem;
}

.App-circle {
  display: inline-block;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: rgb(128, 128, 128);
  vertical-align: text-bottom;
  margin-bottom: 0.65rem;
}

.App-link {
  color: #61dafb;
}
